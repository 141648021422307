.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;  /*#282c34*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.social-icons {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 15px;
  justify-content: center; /* Center horizontally */
  align-items: center;    /* Center vertically */
}

.social-icons a {
  color: #000000; /* Adjust color as needed */
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #0a66c2; /* LinkedIn blue color on hover */
}

#git:hover{
  color: #ffffff; /* GitHub purple color on hover */
}

#resume:hover{
  color: #FF0000;
}

.hover-text {
  display: none; /* Initially hide the text */
  position: absolute; /* Position it relative to the parent*/
  top: 115%; /* Center vertically */
  left: 15%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for centering */
  color: #FF0000; /* Color of the hover text */
  font-size: 12px; /* Adjust font size as needed */
  background-color: rgba(255, 255, 255, 0.8); /* Optional: background for better visibility */
  padding: 5px; /* Optional: padding around the text */
  border-radius: 5px; /* Optional: rounded corners */
  z-index: 1;/* Ensure it appears above the icon  */
  
}

#resume:hover .hover-text {
  display: inline; /* Show the text when hovering over the link */
}

